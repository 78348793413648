// import moment from 'moment';
import moment from 'moment-timezone';

export const formatDate = (stringDate: string, format: string, pattern: string) => {
  moment.tz.setDefault('Asia/Seoul');
  if (stringDate) {
    const parsingDate = pattern ? moment(stringDate, pattern) : moment(stringDate);
    return parsingDate.format(format || 'YYYY-MM-DD HH:mm:ss');
  }
  return '';
};

export const removeTag = (value: string) => {
  const filterValue = value.replace(/(<([^>]+)>)/ig, '');
  return filterValue;
};

export const getNo = (index: number, totalElements: number, listQuery: any) => {
  let totalSize = (listQuery.page - 1) * listQuery.size;
  if (totalSize < 0) totalSize = 0;
  return totalElements - totalSize - index;
};

export const numToString = (value: number) => {
  const StringNum = value.toLocaleString('ko-KR');
  return StringNum;
};

export const regexp = (sentence: string) => {
  sentence.replaceAll(' ', '');
  const data: any|null = new RegExp('[0-9]+[mm|cm|℃]+').exec(sentence);
  if (data == null) return '';
  return data[0];
};
