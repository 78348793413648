















import { UserModule } from '@/store/modules/user';
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'Header',
})
export default class extends Vue {
  @Prop({ default: false }) private sideCollapseVisible!: boolean;

  @Watch('$route.path')
  private handleChangeRoute() {
    this.setMenu();
  }

  mounted() {
    this.setMenu();
  }

  private menu = '대시보드';

  private setMenu() {
    this.menu = (this.$route.meta as any).title || '';
  }

  private handleHamburger() {
    this.$emit('changeVisible', !this.sideCollapseVisible);
  }

  private async handleLogout() {
    await UserModule.LogOut();
    this.$router.push({ path: '/login' });
  }
}
