import request from '@/util/request';
import axios from 'axios';
import qs from 'qs';

const PATH = '/user';

export const login = (data: any) => {
  /* eslint-disable */
  data.grant_type = 'password';
  return axios({
    method: 'post',
    url: `${process.env.VUE_APP_OAUTH_API}/token`,
    data: qs.stringify(data),
    headers: {
      Authorization: 'Basic c2luZ2hhX29hdXRoOnNpbmdoYXNjcmVjdCFAIyQ=',
    },
  });
  /* eslint-enable */
};

export const getUserList = (listQuery: any) => request({
  url: `${PATH}`,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const getUser = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const getLoginedUser = () => request({
  url: `${PATH}/info`,
  method: 'get',
});

export const addUser = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const updateProfile = (data: any) => request({
  url: `${PATH}`,
  method: 'put',
  data,
});

export const updateUser = (uid: string, data: any) => request({
  url: `${PATH}/${uid}`,
  method: 'put',
  data,
});

export const deleteUser = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});

export const uniqueId = (userId: string) => request({
  url: `${PATH}/id-check/${userId}`,
  method: 'get',
});

export const checkPassword = (data: any) => request({
  url: `${PATH}/password-check`,
  method: 'get',
  params: {
    ...data,
  },
});
