import ElementUI from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ko';
import SvgIcon from 'vue-svgicon';
import '@/icons/components';
import * as filter from '@/filters';
import 'moment/locale/ko';

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});

Object.keys(filter).forEach((key) => {
  Vue.filter(key, (filter as { [key: string ]: Function })[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
