






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { routes } from '@/router';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'SideBar',
})
export default class extends Vue {
  @Prop({ default: false }) private sideCollapseVisible!: boolean;

  get routes() {
    return routes;
  }

  mounted() {
    this.activeIndex = this.$route.path;
  }

  private activeIndex = '/';

  private resolvePath(parentPath: string, childPath: string) {
    if (childPath) {
      let path = `${parentPath}/${childPath}`;
      if (parentPath === '/') path = `/${childPath}`;
      return path;
    }
    return parentPath;
  }

  private handleSelectMenu(key: string, keyPath: string[]) {
    if (this.$route.path !== key) this.$router.push(key);
  }

  private hasRole(route: any) {
    if (!route.meta || !route.meta.roles) return true;
    let hasRole = false;
    UserModule.roles.forEach((role: string) => {
      if (route.meta.roles.indexOf(role) > -1) hasRole = true;
    });
    return hasRole;
  }
}
