import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import { login } from '@/api/user';
import {
  getToken,
  getTokenDecode,
  setToken,
  removeToken,
} from '@/util/cookies';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';

export interface IUserState {
  token: string
  name: string
  avatar: string
  introduction: string
  roles: string[]
  email: string
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''

  public name = ''

  public avatar = ''

  public introduction = ''

  public roles: string[] = []

  public email = ''

  public id = ''

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id;
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    const { username, password } = userInfo;
    await login({ username, password }).then((res) => {
      setToken(res.data.access_token);
      this.SET_TOKEN(res.data.access_token);
    }).catch((error : any) => {
      Message.error(error.response.data.error_description);
    });
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }

  @Action
  public async SetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!');
    }
    // tokenCheck(this.token).catch(() => {
    //   throw Error('Verification failed, please Login again.');
    // });
    const data:any = await getTokenDecode();
    if (!data || !data.authorities || data.authorities.length <= 0) {
      this.ResetToken();
      router.push('/login');
      return;
    }
    this.SET_ROLES(data.authorities);
    this.SET_ID(data.user_name);
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    // await logout();
    removeToken();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }
}

export const UserModule = getModule(User);
