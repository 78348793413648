import { UserModule } from '@/store/modules/user';
import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import Home from '../views/Home.vue';
import Layout from '@/layout/Index.vue';

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '기상영향 통계',
      icon: 'el-icon-monitor',
    },
    children: [
      {
        path: '',
        name: 'DashBoard',
        component: () => import('../views/dashboard/Index.vue'),
        meta: {
          title: '통계',
        },
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () => import('../views/statistics/Index.vue'),
        meta: {
          title: '통계 차트',
        },
      },
    ],
  },
  {
    path: '/collection',
    component: Layout,
    meta: {
      title: '뉴스 수집',
      icon: 'el-icon-document-copy',
      roles: ['ROLE_ADMIN'],
    },
    children: [
      {
        path: '',
        name: 'Collection',
        component: () => import('../views/collection/Index.vue'),
        meta: {
          title: '뉴스 수집(수동)',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'scheduler',
        name: 'CollectionScheduler',
        component: () => import('../views/collection/Scheduler.vue'),
        meta: {
          title: '뉴스 수집(자동)',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'history',
        name: 'CollectionHistory',
        component: () => import('../views/collection/History.vue'),
        meta: {
          title: '수집 기록',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'weather-type',
        name: 'WeatherType',
        component: () => import('../views/collection/WeatherType.vue'),
        meta: {
          title: '기상영향 타입 관리',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'setting',
        name: 'CollectionSetting',
        component: () => import('../views/collection/Setting.vue'),
        meta: {
          title: '언론사 설정',
          roles: ['ROLE_ADMIN'],
        },
      },
    ],
  },
  {
    path: '/learning',
    component: Layout,
    meta: {
      title: '학습 설정',
      icon: 'el-icon-files',
      roles: ['ROLE_ADMIN'],
    },
    children: [
      {
        path: 'damage-dictionary',
        name: 'DamageDictionary',
        component: () => import('../views/learning/DamageWords.vue'),
        meta: {
          title: '피해유형 사전',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'cause-dictionary',
        name: 'CauseDictionary',
        component: () => import('../views/learning/CauseWords.vue'),
        meta: {
          title: '피해원인 사전',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'stop_words',
        name: 'StopWordsManaging',
        component: () => import('../views/learning/StopWords.vue'),
        meta: {
          title: '불용어 관리',
          roles: ['ROLE_ADMIN'],
        },
      },
    ],
  },
  {
    path: '/user',
    component: Layout,
    meta: {
      title: '사용자 관리',
      icon: 'el-icon-user',
      roles: ['ROLE_ADMIN'],
    },
    children: [
      {
        path: '',
        name: 'UserManage',
        component: () => import('../views/user/Index.vue'),
        meta: {
          title: '사용자 관리',
          roles: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'role',
        name: 'RoleManage',
        component: () => import('../views/user/Role.vue'),
        meta: {
          title: '권한 관리',
          roles: ['ROLE_ADMIN'],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: '/',
    meta: {
      hidden: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const noAuthorizedPages = ['/login'];

/* eslint-disable */
router.beforeEach(async(to: Route, _: Route, next: any) => {
  if (UserModule.token) {
    await UserModule.SetUserInfo();
    if (to.path === '/login') next({ path: '/' }); // 로그인 페이지에서 토큰이 있다면 홈으로 이동

    /* 페이지 별 권한 체크 */
    if (to.meta && to.meta.roles) {
      let hasRole = false;
      UserModule.roles.forEach((role: string) => {
        if ((to.meta as any).roles.indexOf(role) > -1) hasRole = true;
      });
      if (hasRole) next();
      else next({ path: '/' });
    }
  } else {
    if (noAuthorizedPages.indexOf(to.path) < 0) {
      next({ path: '/login' });
    }
  }
  next();
});
/* eslint-enable */

export default router;
