













import { Component, Vue } from 'vue-property-decorator';
import SideBar from './components/SideBar.vue';
import Header from './components/Header.vue';

@Component({
  name: 'DefaultLayout',
  components: {
    SideBar,
    Header,
  },
})
export default class extends Vue {
  mounted() {
    if (this.$el.clientWidth < 769) this.sideCollapseVisible = true;
  }

  get isChartPage() {
    return this.$route.name === 'Statistics';
  }

  private sideCollapseVisible = false;

  private handleChangeVisible(sideCollapseVisible: boolean) {
    this.sideCollapseVisible = sideCollapseVisible;
  }
}
