import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
// import { UserModule } from '@/store/modules/user';
import router from '@/router';
import { UserModule } from '@/store/modules/user';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000,
});

// Response interceptors
/* eslint-disable */
service.interceptors.response.use(
  (response: any) => {
    const res = response.data;
    if (response.status !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return response;
  },
  (error) => {
    const res = error.response;
    if (res.data.error === 'invalid_token') {
      Message({
        message: '유효하지 않은 토큰입니다. 다시 로그인 해주세요.',
        type: 'error',
        duration: 5 * 1000,
      });
      UserModule.ResetToken();
      router.push('/login');
      return;
    }
    return Promise.reject(error);
  },
);
/* eslint-enable */

export default service;
